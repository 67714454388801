<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Tpo Registration</v-toolbar-title>
      <v-spacer></v-spacer>
      <template>
        <!-- <div color="primary">
          <v-btn color="primary" @click="dialog_attachment = true"
            ><v-icon>mdi-attachment</v-icon>New Tpo Registration</v-btn
          >
        </div> -->
        <div v-if="registration">
          <v-btn color="primary"  dark @click="dialog_attachment = true"
            ><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn
          >
        </div>





        <div v-else>
        </div>
      </template>
    </v-toolbar>
    
     <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>

    <v-row>
      <v-col cols="12" sm="4" v-for="item in learner_tpo_reg" :key="item">
        <v-card>
          <v-card-title class="c-title">
            {{ item.ptype }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" sm="4">
                <span class="lbl">Academic Year</span>
              </v-col>
              <v-col cols="6" md="6"> : {{ item.ay }} </v-col>
            </v-row>
            <!-- <v-row>    
                            <v-col cols="6" sm="4">
                                <span class="lbl">Semester</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.sem}}
                            </v-col>
                        </v-row>  -->
            <v-row>
              <v-col cols="6" sm="4">
                <span class="lbl">Interested</span>
              </v-col>
              <v-col cols="6" md="6">
                <span v-if="item.isactive">
                  :
                  <v-icon class="mr-2 v-icon-size" color="success"
                    >mdi-check-circle-outline</v-icon
                  >
                </span>
                <span v-else>
                  :
                  <v-icon class="mr-2 v-icon-size" color="error"
                    >mdi-close-circle-outline</v-icon
                  >
                </span>
                <!-- : {{item.isactive}} -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-avatar style size="20">
              <!--   <v-icon style size="20" color="error" @click="deleteItem(item)">mdi-delete-empty</v-icon>-->
              <v-icon
                style
                size="20"
                color="error"
                @click="deleteTPORegistration(item)"
                >mdi-delete-empty</v-icon
              >
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-app id="inspire" class>
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="learner_tpo_reg" sort-by="ptype" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Tpo Registration</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>  
                           -->
    <v-dialog v-model="dialog" max-width="500px">
      <!-- <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on">New Tpo Registration</v-btn>
                                </template> -->

      <v-card>
        <v-card-title class="c-title">
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
             <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.ay"
                  autocomplete="off"
                  :readonly="editedIndex == -1 ? false : true"
                  item-text="ay"
                  item-value="id"
                  label="Select Academic Year"
                  persistent-hint
                  :filled="editedIndex == -1 ? false : true"
                  :error="errorMsg.ay ? true : false"
                  :error-messages="errorMsg.ay ? 'Required' : ''"
                  return-object
                  single-line
                  :items="list"
                  @change="check()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="editedIndex != -1">
                Academic Year
                <v-select
                  dense
                  v-model="editedItem.ay"
                  autocomplete="off"
                  :items="list"
                  item-value="id"
                  persistent-hint
                  :error="errorMsg.ay ? true : false"
                  :error-messages="errorMsg.ay ? 'Required' : ''"
                  
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="editedItem.ptype"
                  :items="ptype_list"
                  :readonly="editedIndex == -1 ? false : true"
                  item-text="ptype"
                  item-value="id"
                  label="Select Placement Type"
                  persistent-hint
                  :filled="editedIndex == -1 ? false : true"
                  :error="errorMsg.ptype ? true : false"
                  :error-messages="errorMsg.ptype ? 'Required' : ''"
                  return-object
                  single-line
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="editedIndex != -1">
                Placement Type
                <v-select
                  dense
                  v-model="editedItem.ptype"
                  :items="ptype_list"
                  item-value="id"
                  persistent-hint
                  :error="errorMsg.ptype ? true : false"
                  :error-messages="errorMsg.ptype ? 'Required' : ''"
                ></v-select>
              </v-col>

             

              <!-- <v-col cols="12">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.sem"
                                                        :items="sem_list"
                                                        :readonly="editedIndex==-1?false:true"
                                                        item-text="sem"
                                                        item-value="id"
                                                        label="Select Semester"
                                                        persistent-hint
                                                        :filled="editedIndex==-1?false:true"
                                                        :error="errorMsg.sem?true:false"
                                                        :error-messages="errorMsg.sem?'Required':''"
                                                        return-object
                                                        single-line
                                                    ></v-select>
                                                </v-col> -->
              <!-- <v-col cols="12" v-if="editedIndex != -1">
                                                    Semester
                                                    <v-select dense v-model="editedItem.sem" :items="sem_list" item-value="id" persistent-hint :error="errorMsg.sem?true:false" :error-messages="errorMsg.sem?'Required':''"></v-select>
                                                </v-col> -->
            </v-row>
           <!-- <v-row dense>
              <v-col cols="12">
                <v-checkbox
                  dense
                  v-model="editedItem.isactive"
                  color="success"
                  label="Active?"
                ></v-checkbox>
              </v-col>
              <v-col>
                <input type="hidden" v-model="editedItem.id" name="id" />-->
                <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
            <!--  </v-col>
            </v-row>-->
          </v-container>
          <v-row v-if='!visible && this.editedItem.ay!="" ' justify="center">
          <b class="red--text">Registration close</b>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn v-if='visible' color="primary darken-1"  @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog_attachment">
      <v-card>
        <v-card-title class="c-title">
          <span class="headline">Policy For Students Placements & Internships</span>
          <span style="margin-left: 630px;"><v-icon size="35" @click="dialog_attachment=false" color="red">mdi-close-circle-outline</v-icon></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="ml-8 mr-8">
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
               <strong> 1st Feb 2024 </strong>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <p style="text-align: justify;">As the Central Training & Placement Officer for VI (Vishwakarma institutes ), I wish to lay down
                some policies we have for T&P for this academic year 2024-25(For students in T.Y. 2023-24). The
                formal internship (with ppo) drives have just begun and the placement process should commence in
                mid July 2024. The placement season for this batch will last for about 24 months (Until Feb 2026)</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>1.Single offer per student :</b><br><br> <p style="text-align: justify;">This year we will be offering only ONE SINGLE offer to every
                student through our T&P cell. Every company that comes to campus is a meant for a specific set of
                students and students should apply to appear for a company's test and interviews only if they aspire
                for a job from that company.</p>
                <p style="text-align: justify;">
                We encourage all students to participate in hackathons to obtain job offers since increasingly many
                companies hire via hackathons. If the hackathon is conducted via CTPO, then it will be counted as
                campus placement, but if student participates in hackthons on their own with companies not via
                college or placement office, that will be considered as additional job and students can apply with
                CTPO. Also we encourage all students to participate in competitions like InfyTQ, TCS Codevita
                etc and obtain job with base package (3-4 lacs). Such students will be allowed to participate in
                campus drives.</p>
                <p style="text-align: justify;">
                Though primarily we have a policy of One Job per student, Thus any student who is already
                placed is out of the process and shall not appear for any other company. But from 2025 batch
                onwards we plan to permit students for dream Job as per following table (The following is subject
                to discretion of CTPO and may not be possible in certain cases for example when internships are
                committed we cannot move to another company and so on.) :</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <center>
                <table>
                  <tr>
                    <th style="text-align: center; padding: 10px;">Sr no</th>
                    <th style="text-align: center; padding: 10px;">Existing package CTC(all inclusive package withjoining bonus, incentives)</th>
                    <th style="text-align: center; padding: 10px;">Dream Job permitted</th>
                  </tr>
                  <tr>
                    <th style="text-align: center; padding: 10px;">1</th>
                    <td style="text-align: center; padding: 10px;">Below 10 lacs CTC</td>
                    <td style="text-align: center; padding: 10px;">Permitted for companies above 15 lacs CTC</td>
                  </tr>
                  <tr>
                    <th style="text-align: center; padding: 10px;">2</th>
                    <td style="text-align: center; padding: 10px;">Below 15 lacs CTC</td>
                    <td style="text-align: center; padding: 10px;">Permitted for companies above 25 lacs CTC</td>
                  </tr>
                  <tr>
                    <th style="text-align: center; padding: 10px;">3</th>
                    <td style="text-align: center; padding: 10px;">Above 15 lacs CTC</td>
                    <td style="text-align: center; padding: 10px;">Already have a dream offer</td>
                  </tr>
                </table>
                </center>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12"><br>
                <b>2. Internships converted to Placement offer :</b><br><br>
                <p style="text-align: justify;"> All VI students have the facility of a summer or
                semester long internship or rarely a year long internship in a company. We look at Internship as a
                “Gateway to a Job”. Thus any student who appears for any company at VI desiring internship,
                irrespective of whether the internship has been arranged by CTPO or individual department, is, in
                principal, willing to accept a job offer from that company. Most companies offer a PPO (Pre
                Placement Offer) to the intern at some point of time during or the end of the internship and this
                PPO is <b>Binding on the student</b>. The student cannot reject the job offer stating that he/she is not
                interested in the job/role offered by the company interning the student. There are two categories of
                companies that will come down to campus for selecting interns. One that offers semester or year
                long internship and the other that offer summer internships (But converts to semester internship
                based on performance) Any student selected as a summer intern will not apply for a semester long
                internship as long as the summer internship is not over since some of these summer internships get
                converted to semester long internships. Some companies are specifically for those not interested in
                placements (like those aspiring for higher studies ) and students who apply for these will not be
                allowed for placements.</p>
                <p style="text-align: justify;">
                But several companies that are looking for interns have also clearly mentioned intention to convert
                to PPOs. This information is communicated to students at the time of application process for a
                company. For example Seagate and Infineon come for year long internships with intention to give
                PPO. Students selected for such companies are out of entire placement process until next June
                2025. Some companies lik DB, Cummins offer semester internship and since they give PPO as
                well, we will wait for results of PPO to come (by Jan 2025) before allowing such students for
                placements. All this information is communicated when we seek registrations for internships and
                every student must read them carefully before applying for an internship.</p>
                <p style="text-align: justify;">
                Our general strategy is also to invite companies that offer 6-6.5 lacs Plus for internships in
                Semester 1 (for IT roles) and 5 lacs plus for core branches. Rest will be invited for internships in
                semester 2. However this year we may also invite companies that offer 4-6 lacs as PPO (IT) for
                internships in Semester 1 mainly for students with low scores. But if a student with a very high
                merit applies for a company offering less PPO, then he/she will not be allowed later for companies
                that offer higher salaries. So please think carefully before applying for an internship with a
                company. And if a student is not interested in placements he/she may sit for companies that are
                not offering PPO. For some companies who have come for internships we will mention that
                students who get internship here, could sit for X number of companies that offer only placements
                and such notifications may change company to company and are communicated to students during
                registrations.</p>
                <p style="text-align: justify;">
                Even if a student applies for internship/job to a company on his own, if that company has an
                association with VI, then such a selection will also be treated as part of our VI selection process
                and same policies would apply.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>3. Duration of Internships :</b> <br><br>
                <p style="text-align: justify;"> A summer internship generally lasts about 2 months (June 1st to July 31st) 
                and a semester long internship needs to have a duration of minimum 4.5 month. (August 1st to December 15th or January
                1st to May 15th ). However in some cases, a semester long internship (especially in odd semester) may run from July 1st
                to December 15th and that is acceptable too.</p>
                <p style="text-align: justify;">
                It is also possible that a company may come for summer internship (2 months) initially, but later 
                convert it to (4.5 month) semester long internship. Year long internship (11-12 months) lasts from June 1st to May 31st.
                These are left to the discretion of the company and students need to accept an extended internship from a company once they apply to the same.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>4. Internship for students already placed: </b><br><br>   
                <p style="text-align: justify;">Since internship is viewed as a “Gateway to a job” and
                since we have Single offer per student policy in place, once a student obtains a job, he/she will not
                be allowed to appear for any other internship offer. He may do internship in the same company
                where he is placed (if they permit). VI will not allow placed students to sit for internship interviews
                through any process. Such students, who may not obtain an internship, may do elective courses
                offered by the department during their Internship Module or do internship in a start up etc on their
                own.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>5. Seeking Exceptions to rule is discouraged: </b> <br><br>
                <p style="text-align: justify;">When a company comes to college for
                internship/placement they specify their criteria (no backlogs, CPI above 8 etc) and we forward the
                criteria to all students. It has been noticed that students who do not fit into the criteria keep seeking
                exceptions. For example a company says “No backlogs” and some student who has one backlog
                keeps asking if he can apply. Similarly a company has specified cut of as 8.5 CPI and students with
                CPI 8.49 ask if they can apply. The answer to these questions is a clear <b>NO</b>. So we expect students
                to read the criteria carefully and not seek exceptions if they do not fit the criteria.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>6. Dress code during interviews for internships/placements:  </b><br><br> 
                <p style="text-align: justify;">All students should be present in Formals.</p>
                <p style="text-align: justify;">VIT students : Full sleeve white shirt and black trousers with black/brown shoes for boys.</p>
                <p style="text-align: justify;">VIIT and VU students : Full sleeve sky blue shirt and black trousers with black/brown shoes for boys.</p>
                <p style="text-align: justify;">Either same colour code as above or Punjabi dress with sleeves for girls.</p>
                <p style="text-align: justify;">(Students wearing jeans, T-shirts or any other dress other than what is mentioned above will not be
                allowed to appear for the placement process) They should carry updated CV along with them. In
                some cases, interviews will happen over skype /video etc. Please be dressed in formal attire during
                online interviews too.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>7. Disciplinary Actions:</b><br><br>
                <p style="text-align: justify;"> CTPO will not hesitate to take disciplinary action that includes debarring a student from appearing for internships/final placements under any of the following or similar
                cases of guilt (following are only examples) :</p>
                <p style="text-align: justify;">a. Applying for a company and remaining absent or being late. Not coming in formal attire or color
              code mentioned. Getting shortlisted for a test but skipping it.</p>
              <p style="text-align: justify;">b. Appearing for an interview and informing the interviewer that he/she has no intention to join the
              company as either an intern or final job seeker.</p>
              <p style="text-align: justify;">c. Rejecting PPO offered by company where one is an intern.</p>
              <p style="text-align: justify;">d. Giving false information during the initial process of filling up required details.</p>
              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <b>8. Escalation Process:</b><br><br><p style="text-align: justify;"> In case students have any queries they should do the following:</p>
                <p style="text-align: justify;">a. Read the mails/whatsapp messages and criteria carefully. 100% information is conveyed in the mail sent.</p>
                <p style="text-align: justify;">b. In case of doubts meet the office staff of TPO section at VIT between 10 to 6 p.m. (Monday to Friday and Saturday 9 to 1 p.m.)</p>
                <p style="text-align: justify;">c. In case someone is out of station (as an intern) student may mail tpo_students@vit.edu. (Please note that tpo@vit.edu is the mail id for
                companies to write to TPO, it is not for students to ask their queries so DO NOT write mails to tpo@vit.edu)</p>
                <p style="text-align: justify;">d. In case none of the above methods solved your query you first meet your respective TPO in college, else approach the undersigned.</p>
                <p style="text-align: justify;">e. Students can always meet me in my office. No WhatsApp or phone calls from students to me or my team. We shall be soon creating a whatsapp group of Class representatives of each division
                where CRs can ask questions. Else everything is communicated on TELEGRAM channel.</p>
                -sd

              </v-col>
            </v-row>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <strong>Prof S.B.Karthick</strong><br>
                <strong>Central Training and Placement Officer</strong><br>
                <strong>VI-group</strong><br>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <center>
                <div>
                  <v-btn color="primary" @click="()=> {dialog_attachment=false; dialog=true}">
                    I Agree
                  </v-btn>
                  <v-btn color="primary" class="ml-10" dark @click="dialog_attachment = false">
                    Cancel
                  </v-btn>
                </div>
                </center>
              </v-col>
            </v-row>
          </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <template v-slot:item.action="{ item }">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-avatar style size="20" >
                                            <v-icon style size="20" color="error" @click="deleteItem(item)">mdi-delete-empty</v-icon>
                                        </v-avatar>
                                    </template>
                                    <span>edit</span>
                                </v-tooltip>
                            </template>  -->
    <!-- </v-toolbar> -->
    <!-- </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                     </template>
                </v-data-table>
            </template> 
        </v-app> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    errorMsg: {
      ptype: false,
      //  sem: false,
      ay: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    dialog_attachment: false,
    search: "",
    load: false,
    dialog: false,
    learner_tpo_reg: null,
    ptype_list: null,
    visible:false,
    //            sem_list: null,
    ay_list: null,
    list : null,
     loading:false,
    headers: [
      {
        text: "Placement Type",
        value: "ptype",
      },
      {
        text: "Academic Year",
        value: "ay",
      },
      // {
      //     text: "Semester",
      //     value: "sem",
      // },
      {
        text: "Active",
        value: "isactive",
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: "",
      ptype: "",
      // sem: "",
      ay: "",
      isactive: true,
    },
    defaultItem: {
      id: "",
      ptype: "",
      // sem: "",
      ay: "",
      isactive: true,
    },
    ayreg: [],
    registration:"",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Tpo Registration"
        : "Edit Tpo Registration";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.onload();
  },

  methods: {
      check(){
          for(var i in this.ayreg)
          {
          if(this.ayreg[i][this.editedItem.ay])
            {
                this.visible=true;
                
                  return
            }
            else
            {
                    this.visible=false;
                   
                    
            }
          }
         //console.log(this.ayreg[this.editedItem.ay])
      },
    onload() {
       this.loading= true;
      axios
        .post("/TPORegistration/addlearnerTpoReg")
        .then((res) => {
          if (res.data.msg == "200") {
            this.learner_tpo_reg = res.data.learner_tpo_reg;
            this.ptype_list = res.data.ptype_list;
            //this.sem_list = res.data.sem_list;
            this.ay_list = res.data.ay_list.sort();
            this.list = res.data.list;
            this.ayreg = res.data.ayreg;
            this.load = true;
             this.loading= false;
            //console.log(this.ayreg)
            this.registration = res.data.registration;
            //console.log("A:"+res.data.registration)
            
          } else {
          }
        })
        .catch((error) => {
         // window.console.log(error);
        });
    },
    initializeErrorMsgFalse() {
      this.errorMsg.ptype = false;
      // this.errorMsg.sem = false;
      this.errorMsg.ay = false;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      this.editedIndex = this.learner_tpo_reg.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          //  console.log("edit.." + this.editedItem);

          axios
            .post("/TPORegistration/edit", this.editedItem)
            .then((res) => {
              if (res.data.msg == "200") {
                Object.assign(
                  this.learner_tpo_reg[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar(
                  "#4caf50",
                  "TPO Registration Updated Successfully..."
                ); // show snackbar on success
              }
            })
            .catch((error) => {
              //window.console.log(error);
            });

          this.close();
        }
      } else {
        if (this.isValidated()) {
          axios
            .post("/TPORegistration/savelearnerTpoReg", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.learner_tpo_reg.push(this.editedItem);
                this.showSnackbar(
                  "#4caf50",
                  " Tpo Registration Added Successfully..."
                ); // show snackbar on success
                this.onload();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar(
                  "#b71c1c",
                  " Tpo Registration Already Present!!!"
                ); // show snackbar on error
              } else if (res.data.status.code == "DE") {
                this.showSnackbar(
                  "#b71c1c",
                  "You are not allowed to do multiple registrations in same Academic-Year !"
                );
              }
            })
            .catch((error) => {
              //window.console.log(error);
            });

          this.close();
        }
      }
    }, // end of save()

    deleteTPORegistration(item) {
      //console.log("a");

      const data = {
        deleteitem: item.id,
      };

      axios
        .post("/TPORegistration/deleteTPORegistration", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onload();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
         // window.console.log(error);
        });
      this.close();
    },

    // deleteItem(item) {
    //     var r = confirm("Are you sure you want to delete this item?");
    //     if (r) {
    //         const tpoId = {
    //         id: item.id,
    //         };
    //         axios
    //         .post("/TPORegistration/deleteTPOReg", tpoId)
    //         .then(res => {
    //             if (res.data.msg == "200") {
    //             this.onload()
    //             this.showSnackbar("#4CAF50", "TPO Registration Deleted...");

    //             }
    //         })
    //         .catch(error => {
    //             this.showSnackbar("#b71c1c", "Something went wrong!!!");
    //         })
    //         .finally(() => {});
    //     } else {}
    //     },

    isValidated() {
      //  if (this.editedItem.ptype && this.editedItem.ay && this.editedItem.sem) {
      if (this.editedItem.ptype && this.editedItem.ay) {
        return true;
      } else {
        if (!this.editedItem.ptype) {
          this.errorMsg.ptype = true;
          this.showSnackbar("#b71c1c", " Select Placement Type... ");
        } else if (!this.editedItem.ay) {
          this.errorMsg.ay = true;
          this.showSnackbar("#b71c1c", " Select Academic year... ");
        }
        //    else if (!this.editedItem.sem) {
        //         this.errorMsg.sem = true;
        //          this.showSnackbar("#b71c1c", " Select Semester... ");
        //     }
        return false;
      }
    },
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

table, td, th {
  border: 1px solid;
}

table {
  width: 900px;
  border-collapse: collapse;
}
</style>
